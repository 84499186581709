.view {
  border: #ce1313 solid 3px;
  margin: 0px;
  padding: 0px;
}

.viewTranslate {
  border: #67ce13 solid 3px;
  margin: 0px;
  padding: 0px;
}


.targetView {
  border: #0f37b7 solid 3px;
  margin: 0px;
  padding: 0px;
}