.dateTimeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Roboto Light', sans-serif;
  font-weight: lighter;
}

.time {
  font-size: 100px;
}

.date {
  font-size: 25px;
}