.currencyWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Roboto Light', sans-serif;
  font-weight: lighter;
  border: 1px solid white;
  border-radius: 20px;
  padding: 10px;
}

.currency {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-size: 50px;
  justify-content: space-evenly;

  .name {
    display: flex;
    font-size: 20px;
    padding: 20px;
    border-radius: 50px;
    align-items: center;
  }

  .value {
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: right;
    width: 100%;

    .nationalName {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  .dayGraph {
    display: flex;
    font-size: 30px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    min-width: 90px;

    .delta {
      margin-left: 5px;
      font-size: 20px;
    }

    &.up {
      color: #82d36a;
    }

    &.down {
      color: #e76964;
    }

    &.opacity {
      opacity: 0;
    }
  }
}