@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clock {
  display: flex;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  color: #444;
  text-align: center;
  z-index: 1000;
}

.clock::after {
  background: #ce1313;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
}

.hour_hand {
  position: absolute;
  width: 4px;
  height: 60px;
  background: #eee2e2;
  top: 30%;
  left: 49%;
  transform-origin: bottom;
  border-radius: 2px;
}

.min_hand {
  position: absolute;
  width: 2px;
  height: 90px;
  background: #eee2e2;
  top: 20.5%;
  left: 49.5%;
  transform-origin: bottom;
  border-radius: 1px;
}

.sec_hand {
  position: absolute;
  width: 2px;
  height: 118px;
  background: red;
  top: 10.5%;
  left: 50%;
  transform-origin: bottom;
}

.clock span {
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  color: #94d8ef;
}

.twelve {
  top: 10px;
  left: 46%;
}

.one {
  top: 10%;
  right: 26%;
}

.eleven {
  top: 10%;
  left: 26%;
}

.two {
  top: 25%;
  right: 10%;
}

.three {
  right: 10px;
  top: 46%;
}

.four {
  right: 30px;
  top: 67%;
}

.five {
  right: 78px;
  top: 80%;
}

.six {
  bottom: 10px;
  left: 50%;
}

.seven {
  left: 80px;
  top: 82%;
}

.eight {
  left: 30px;
  top: 67%;
}

.nine {
  left: 10px;
  top: 46%;
}

.ten {
  top: 25%;
  left: 10%;
}

.clock .date {
  //color: white;
  bottom: -40px;
  width: 100%;
  //background-color: #94d8ef;
}