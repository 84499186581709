.mainScreen {
  display: flex;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: white;

  h1 {
    font-size: 180px;
  }
}
