.switcherWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.switch {
  opacity: 0;
  position: fixed;
}

.hide {
  animation: hide-el;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0s;

}

.visible {
  opacity: 0;
  position: fixed;
  animation: show-el;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
}

@keyframes show-el {
  0% {
    opacity: 0;
  }
  100% {
    position: relative;
    opacity: 1;
  }
}

@keyframes hide-el {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    position: fixed;
  }
}